import Table from '@material-ui/core/Table';
import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Query } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { TEAM_GAMELOG } from '../Query';
import QueryProgressIndicator from '../Util/QueryProgressIndicator';
import { getDefaultYear, getDefaultWeek } from '../Util/FootballWeeks';

const styles = (theme) => ({
    divider: {
        //background:'#fafafa'
        background: theme.palette.primary.dark,
        height: 3,
        opacity: 0.5,
        margin: 4,
    },
    table: {
        fontSize: '.75rem',
        maxWidth: 800,
    },
    tableCell: {
        fontSize: '.8rem',
    },
});

export const Gamelog = withStyles(styles, { withTheme: true })(({ classes, abbrev }) => {
    const queryVariables = {
        abbrev,
        year: getDefaultYear(),
        week: getDefaultWeek(),
    };
    console.log(queryVariables);
    return (
        <Query query={TEAM_GAMELOG} variables={queryVariables}>
            {({ loading, error, data }) => {
                if (loading) return <QueryProgressIndicator />;
                if (error) return <p>Error :(</p>;
                const { teamGamelog } = data;
                const teamName = teamGamelog?.length ? teamGamelog[0].team : null;
                // console.log(linesByBook); undefined
                return (
                    <React.Fragment>
                        <div>{teamName} Gamelog</div>
                        <div className={classes.divider}></div>
                        <Table className={classes.table} size="small" padding="checkbox">
                            <TableHead>
                                <TableRow>
                                    <TableCell>opp_team</TableCell>
                                    <TableCell>week</TableCell>
                                    <TableCell>date</TableCell>
                                    <TableCell>day</TableCell>
                                    <TableCell>time</TableCell>
                                    <TableCell>away_score</TableCell>
                                    <TableCell>home_score</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {teamGamelog.map((game, idx) => {
                                    const {
                                        opp_team,
                                        week,
                                        gameday,
                                        weekday,
                                        gametime,
                                        away_score,
                                        home_score,
                                    } = game;
                                    const showDivider = false;
                                    let divider = showDivider ? (
                                        <TableRow className={classes.divider}>
                                            <TableCell colSpan={5}></TableCell>
                                        </TableRow>
                                    ) : null;
                                    return (
                                        <React.Fragment key={idx}>
                                            {divider}
                                            <TableRow>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    className={classes.tableCell}
                                                >
                                                    {opp_team}
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {week}
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {gameday}
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {weekday}
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {gametime}
                                                </TableCell>

                                                <TableCell className={classes.tableCell}>
                                                    {away_score}
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {home_score}
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </React.Fragment>
                );
            }}
        </Query>
    );
});
